import { ActionTypes } from "../types";

interface FetchDataAction {
  type: ActionTypes,
  payload: any
}

const dataReducer = (state: any = {}, action: FetchDataAction) => {
  switch (action.type) { 
    case ActionTypes.fetchData:
      return action.payload;
    default:
      return state
  }
}

export default dataReducer;