import React, { useState, useEffect } from 'react';
import { NewsInterface, ContentElment } from "../App";
import { Scroll } from "../components";
import parse from 'html-react-parser';
import YouTube from 'react-youtube';
import { Helmet } from "react-helmet";

interface NewsProps {
    data: NewsInterface,
    scrollText?: string
}

let timer1: any;

export default function News(props: NewsProps) {    

    const { data, scrollText = "" } = props;

    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        timer1 = setTimeout(() => {
            setLoaded(true);
        }, 250);
        
        return () => {
            clearTimeout(timer1);
        }
    }, []);

    const returnContent = (contents: any[]) => {
        if (!contents.length) return;
        return contents.map(({type = "", id = "", content = "", src = "", alt = ""}: ContentElment, i: number) => {
            switch (type) {
                case "medium_text":        
                    return <div key={i} className="medium_text">{parse(content)}</div>
                case "youtube":        
                    return <div key={i} className="iframe_holder"><YouTube videoId={id} /></div>
                case "text":        
                    return <div key={i} className="text_holder">{parse(content)}</div>
                case "image":        
                    return <img key={i} alt={alt} src={src} />
                default:
                    return <div key={i}></div>
            }
        });
    }

    const returnHead = (headData?: string) => {
        if (!headData) return;
        try {
            const html = parse(headData);
            return html;
        } catch(err) {}
    }
    
    return (
        <div className={"news_pop" + (loaded ? " loaded" : "")}>
            <Helmet>{returnHead(data.head_html)}</Helmet>
            <Scroll relative={true} mb="7.2rem" scrollText={scrollText} />
            <h1>{data.name || ""}</h1>
            <div className="content">
                {returnContent(data.contents || [])}
            </div>
        </div>
    )
}
