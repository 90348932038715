import React from 'react';
import { Parallax } from "../components";
import { HouseInterface } from "../App";
import { Helmet } from "react-helmet";
import parse from 'html-react-parser';

interface HouseProps {
    data: HouseInterface,
    scrollText?: string
}

export default function House(props: HouseProps) {
    const {data, scrollText = "" } = props;
    const { info = "", text = "", photos = [], head_html = "", downloads = [], name = "" } = data;

    const returnHead = (headData?: string) => {
        if (!headData) return;
        try {
            const html = parse(headData);
            return html;
        } catch(err) {}
    }

    return (
        <div className="house_pop">
            <Helmet>{returnHead(head_html)}</Helmet>
            <Parallax 
                type="house" 
                elements={photos} 
                name={name} 
                text={text} 
                downloads={downloads}
                info={info} 
                scrollText={scrollText} 
            />
        </div>
    )
}
