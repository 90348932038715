import React, { useEffect } from 'react';


export default function NotFound() {

    useEffect(() => {
        window.location.href = "/404.html";
    }, []);


    return (
        <div>
            
        </div>
    )
}
