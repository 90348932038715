import React from 'react';
import parse from 'html-react-parser';
import { Helmet } from "react-helmet";

export default function found({ headHtml = "" }) {

    const returnHead = headHtml => {
        if (!headHtml) return;
        try {
            const html = parse(headHtml);
            return html;
        } catch(err) {}
    }

    return (
        <div>
            <Helmet>{returnHead(headHtml)}</Helmet>
        </div>
    )
}
