import { Dispatch } from 'redux';
import { ActionTypes } from '../types';

export const fetchData = () => async (dispatch: Dispatch) => { 
  let data: any;

  try {
    const dataObject = document.getElementById('json');
    if (dataObject) {
      data = JSON.parse(dataObject.innerHTML);
    }
  } catch(err) {
    console.log(err);
  }
  
  if (data) dispatch({
    type: ActionTypes.fetchData,
    payload: data
  })
}