import { combineReducers } from "redux";
import dataReducer from './data_reducer';

export interface StoreState {
  store: any
}

const rootReducer = combineReducers<StoreState>({ store: dataReducer });

export default rootReducer;
