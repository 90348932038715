import $ from "jquery";
import { gsap } from "gsap";
import { ScrollScene } from "scrollscene";
import ScrollReveal from 'scrollreveal'

const homeParallax = () => {
  if (!$(".index_page").is(":visible")) return;

  let debounce;

  const initParallax = el => {
    const index = el.id.split('_')[1];
    const trigger = document.getElementById(`triggerer_${index}`);
    const nodeHeight = el.clientHeight;
    const timeline = gsap.timeline({ paused: true });
    const variation = parseInt(Math.random() * 20) + 20;

    timeline.fromTo(
      el,
      {
        y: variation
      },
      {
        y: -variation,
        duration: 1,
        ease: "power2.out",
      }
    );

    new ScrollScene({
      triggerElement: trigger,
      gsap: { timeline },
      duration: window.innerHeight + nodeHeight
    });
  }

  const Reveal = ScrollReveal;
  if (!Reveal) return;

  const options = {
    delay: 200,
    distance: '0px',
    offset: '200px',
    duration: 0,
    opacity: 1,
    beforeReveal: function (el) {
      el.classList.add('loaded');
    },
    afterReveal: function (el) {
      setTimeout(function() {
        if (el.closest('.home') && el.classList.contains('img_box')) {
          const parent = el.closest('.home');
          parent.classList.add('afterLoad');
          if (parent.classList.contains('move')) {
            initParallax(parent);
          }
        } else {
          el.classList.add('afterLoad');
          if (el.classList.contains('move')) {
            initParallax(el);
          }
        }
      }, 2500);
    }
  }
  let container = window.innerWidth > 1000 ? document.querySelectorAll('main')[0] : document.querySelectorAll('html, body')[0];
  Reveal({ ...options, container }).reveal('.reveal_0, .reveal_1, .reveal_2, .reveal_3');

  function resize() {
    clearTimeout(debounce);
    debounce = setTimeout(() => {
      Reveal().destroy();
      container = window.innerWidth > 1000 ? document.querySelectorAll('main')[0] : document.querySelectorAll('html, body')[0];
      Reveal({ ...options, container }).reveal('.reveal_0, .reveal_1, .reveal_2, .reveal_3');
    }, 400);
  }

  $(window).on('orientationchange', () => {
    resize();
  });
  $(window).on('resize', () => {
    if (window.innerWidth > 1024) {
      resize();
    }
  });
};

export default homeParallax;